









































































































































































































































































































































































import { Messages } from "@/models/enums/messages.enum";
import {
  AddressDataLines,
  AddressDataList,
} from "@/models/interface/contact.interface";
import { contactServices } from "@/services/contact.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { debounce, debounceProcess } from "@/helpers/debounce";
import Vue from "vue";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import store from "@/store";

interface PlainOptions {
  label: string;
  value: string;
  disabled: boolean;
}

export default Vue.extend({
  name: "addressDetails",
  props: ["dataTaxType", "columnTabPane"],
  data() {
    this.getRegion = debounceProcess(this.getRegion, 400);
    return {
      mode: "create" as string,
      cardBeforeUpdate: {} as AddressDataList,
      listBillTo: this.$store.state.contactStore
        .listBillTo as AddressDataList[],
      listShipTo: this.$store.state.contactStore
        .listShipTo as AddressDataList[],
      loadingSave: false as boolean,
      loadingFetch: false as boolean,
      loadingPostalCode: false as boolean,
      dataRegionCity: [] as string[],
      dataListPostalCode: [] as string[],
      selectedRowKeys: [] as number[],
      dataSource: [] as AddressDataLines[],
      columnsTable: [
        {
          title: "PIC Name",
          dataIndex: "picName",
          key: "picName",
          width: 170,
          scopedSlots: { customRender: "picName" },
          responsiveColInput: [
            {
              name: "picName",
              placeholder: "PIC Name",
              style: "width: 100%",
              disabled: false,
            },
            {
              name: "telephoneNo",
              placeholder: "Telephone Number",
              style: "width: 100%",
              disabled: false,
            },
            {
              name: "phoneNo",
              placeholder: "Phone Number",
              style: "width: 100%",
              disabled: false,
            },
            {
              name: "email",
              placeholder: "Email",
              style: "width: 100%",
              disabled: false,
            },
            {
              name: "position",
              placeholder: "Position",
              style: "width: 100%",
              disabled: false,
            },
          ],
        },
        {
          title: "Telephone Number",
          dataIndex: "telephoneNo",
          key: "telephoneNo",
          width: 170,
          scopedSlots: { customRender: "telephoneNo" },
        },
        {
          title: "Phone Number",
          dataIndex: "phoneNo",
          key: "phoneNo",
          width: 170,
          scopedSlots: { customRender: "phoneNo" },
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          width: 170,
          scopedSlots: { customRender: "email" },
        },
        {
          title: "Position",
          dataIndex: "position",
          key: "position",
          width: 170,
          scopedSlots: { customRender: "position" },
        },
      ],
      city: "" as string,
      search: "" as string,
      params: "" as string,
      addressDataList: [] as AddressDataList[],
      // valueRadio: 'shipTo' as string,
      plainOptions: [
        {
          label: this.$t("lbl_ship_to"),
          value: "Ship To",
          disabled: false,
        },
        {
          label: this.$t("lbl_bill_to"),
          value: "Bill To",
          disabled: false,
        },
      ] as PlainOptions[],
      valueCheckbox: ["Ship To"] as string[],
      titleForm: "lbl_add_new_address" as string,
      form: this.$form.createForm(this, { name: "addressDetails" }),
      formRules: {
        city: {
          label: "lbl_city_district",
          name: "city",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "city",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        country: {
          label: "lbl_country",
          name: "country",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "country",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        postalCode: {
          label: "lbl_postal_code",
          name: "postalCode",
          placeholder: "lbl_postal_code_placeholder",
          decorator: [
            "postalCode",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        address: {
          label: "lbl_address",
          name: "address",
          placeholder: "lbl_address_placeholder",
          decorator: [
            "address",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        location: {
          label: "lbl_location",
          name: "location",
          placeholder: "lbl_location_placeholder",
          decorator: [
            "location",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        taxType: {
          label: "lbl_tax_type",
          name: "taxType",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "taxType",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
    };
  },
  methods: {
    handleInput(value, key, _column, columnName) {
      this.dataSource[key] = { ...this.dataSource[key], [columnName]: value };
      this.dataSource = this.dataSource.slice();
    },
    handleAddRow() {
      this.dataSource = [
        ...this.dataSource,
        {
          picName: "",
          telephoneNo: "",
          phoneNo: "",
          email: "",
          position: "",
          key: this.dataSource.length,
        },
      ];
    },
    handleDeleteRow() {
      if (this.selectedRowKeys.length > 0) {
        this.$confirm({
          title: `Are you sure want to delete these item?`,
          content: `${this.selectedRowKeys.length} data will be deleted`,
          onOk: () => {
            this.dataSource = this.dataSource.filter(
              (_data, index) => !this.selectedRowKeys.includes(index)
            );
            this.selectedRowKeys = [];
          },
          onCancel() {
            return;
          },
        });
      } else {
        this.$notification.error({
          message: "Error",
          description: "Select data first to delete",
        });
      }
    },
    onSelectChange(value) {
      this.selectedRowKeys = value;
    },
    getListPostalCode(valueSearch) {
      if (this.city) {
        const params: RequestQueryParamsModel = {
          page: 0,
          limit: 10,
          search: `city~*${this.city}*`,
        };
        if (valueSearch) params.search += `_AND_code~*${valueSearch}*`;
        this.loadingPostalCode = true;
        contactServices
          .listRegionCode(params)
          .then(res => {
            res = res.sort();
            this.dataListPostalCode = res;
          })
          .finally(() => (this.loadingPostalCode = false));
      } else {
        this.$notification.error({
          description: "Select City First to get postal code",
          message: "Error",
        });
      }
    },
    handleCancel() {
      this.valueCheckbox = ["Ship To"];
      this.titleForm = "lbl_add_new_address";
      this.mode = "create";
      this.plainOptions[0].disabled = false;
      this.plainOptions[1].disabled = false;
      this.cardBeforeUpdate = {} as AddressDataList;
      this.form.resetFields();
    },
    commitToStore() {
      store.commit(
        `contactStore/${Mutations.SET_LIST_BILL_TO}`,
        this.listBillTo
      );
      store.commit(
        `contactStore/${Mutations.SET_LIST_SHIP_TO}`,
        this.listShipTo
      );
    },
    addNewAddress() {
      this.titleForm = "lbl_add_new_address";
      this.mode = "create";
      this.dataSource = [];
      this.plainOptions[0].disabled = false;
      this.plainOptions[1].disabled = false;
      this.cardBeforeUpdate = {} as AddressDataList;
      this.form.resetFields();
    },
    setPrimaryCard(_dataCard, index, billOrShip): void {
      this.$confirm({
        title: `Are you sure want to set these item to primary?`,
        content: `This items will be set to primary.`,
        onOk: () => {
          switch (billOrShip) {
            case "Bill To":
              this.listBillTo.forEach(data => {
                if (data.billTo) {
                  data.primaryBillTo = false;
                }
              });
              this.listBillTo[index].primaryBillTo = true;
              break;
            case "Ship To":
              this.listShipTo.forEach(data => {
                if (data.shipTo) {
                  data.primaryShipTo = false;
                }
              });
              this.listShipTo[index].primaryShipTo = true;
              break;
            default:
              break;
          }
          this.listShipTo = this.listShipTo.slice();
          this.listBillTo = this.listBillTo.slice();
          this.commitToStore();
        },
        onCancel() {
          return;
        },
      });
    },
    updateCard(dataCard, _index, billOrShip): void {
      this.titleForm = "lbl_update_address";
      this.mode = "update";
      this.cardBeforeUpdate = dataCard;
      this.form.setFieldsValue({
        country: dataCard.country,
        city: dataCard.cityDistrict,
        postalCode: dataCard.postalCode,
        address: dataCard.address,
        location: dataCard.location,
        picName: dataCard.picName,
        picContactNumber: dataCard.picContactNumber,
        idCardNumber: dataCard.idCardNumber,
        taxType: dataCard.taxType,
      });
      this.dataSource = dataCard.addressDataLines
        ? dataCard.addressDataLines.map((dataMap, indexMap) => {
            return { ...dataMap, key: indexMap };
          })
        : [];
      this.dataSource = this.dataSource.slice();
      switch (billOrShip) {
        case "Bill To":
          this.plainOptions[0].disabled = true;
          this.plainOptions[1].disabled = false;
          this.valueCheckbox = ["Bill To"];
          break;
        case "Ship To":
          this.plainOptions[0].disabled = false;
          this.plainOptions[1].disabled = true;
          this.valueCheckbox = ["Ship To"];
          break;
        default:
          break;
      }
    },
    deleteCard(_dataCard, index, billOrShip): void {
      this.$confirm({
        title: `Are you sure want to delete these item?`,
        content: `This items will be deleted.`,
        onOk: () => {
          switch (billOrShip) {
            case "Bill To":
              this.listBillTo = this.listBillTo.filter((data, indexBillTo) => {
                if (index !== indexBillTo) {
                  return data;
                } else {
                  return;
                }
              });
              this.$notification.success({
                description: Messages.DELETE_SUCCESS + " Please Save Data !",
                message: "Success",
                duration: 30,
              });
              break;
            case "Ship To":
              this.listShipTo = this.listShipTo.filter((data, indexShipTo) => {
                if (index !== indexShipTo) {
                  return data;
                } else {
                  return;
                }
              });
              this.$notification.success({
                description: Messages.DELETE_SUCCESS + " Please Save Data !",
                message: "Success",
                duration: 30,
              });
              break;
            default:
              break;
          }
          this.commitToStore();
        },
        onCancel() {
          return;
        },
      });
    },
    checkDuplicate(values, shipToOrBillTo): number {
      const data =
        shipToOrBillTo === "Ship To" ? this.listShipTo : this.listBillTo;
      return data.findIndex(
        item =>
          item.country === values.country &&
          item.cityDistrict === values.city &&
          item.postalCode === values.postalCode &&
          item.idCardNumber?.toLowerCase() ===
            values.idCardNumber?.toLowerCase() &&
          item.taxType === values.taxType
      );
    },
    checkString(value): string {
      if (value && typeof value === "string") return value;
      else return "";
    },
    notificationSuccessSave() {
      debounce(() => {
        this.$notification.success({
          description: Messages.CREATE_SUCCESS + " Please Save Data !",
          message: "Success",
          duration: 30,
        });
      }, 50);
    },
    handleCreate(values) {
      if (this.valueCheckbox.includes("Ship To")) {
        let checkDataByfieldShipTo = this.checkDuplicate(values, "Ship To");
        if (checkDataByfieldShipTo === -1) {
          this.listShipTo = [
            ...this.listShipTo,
            {
              shipTo: true,
              billTo: false,
              primaryShipTo: !this.listShipTo.find(data => data.shipTo === true)
                ? true
                : false,
              primaryBillTo: false,
              country: values.country,
              cityDistrict: values.city,
              location: this.checkString(values.location),
              postalCode: values.postalCode, // clear
              address: values.address,
              picName: values.picName,
              picContactNumber: values.picContactNumber,
              idCardNumber: values.idCardNumber,
              taxType: values.taxType,
              addressDataLines: this.dataSource,
            },
          ];
          this.notificationSuccessSave();
        } else
          this.$notification.error({
            description: "Ship To already exist",
            message: "Error",
            duration: 30,
          });
      }
      if (this.valueCheckbox.includes("Bill To")) {
        let checkDataByfieldBillTo = this.checkDuplicate(values, "Bill To");
        if (checkDataByfieldBillTo === -1) {
          this.listBillTo = [
            ...this.listBillTo,
            {
              shipTo: false,
              billTo: true,
              primaryShipTo: false,
              primaryBillTo: !this.listBillTo.find(data => data.billTo === true)
                ? true
                : false,
              country: values.country,
              cityDistrict: values.city,
              location: this.checkString(values.location),
              postalCode: values.postalCode, // clear
              address: values.address,
              picName: values.picName,
              picContactNumber: values.picContactNumber,
              idCardNumber: values.idCardNumber,
              taxType: values.taxType,
              addressDataLines: this.dataSource,
            },
          ];
          this.notificationSuccessSave();
        } else
          this.$notification.error({
            description: "Bill To already exist",
            message: "Error",
            duration: 30,
          });
      }
    },
    checkUpdateShipToOrBillTo(shipToOrBillTo): number | null {
      let index = null as number | null;
      let dataForLoop =
        shipToOrBillTo === "Ship To" ? this.listShipTo : this.listBillTo;
      dataForLoop.forEach((value, indexData) => {
        if (
          value.shipTo === this.cardBeforeUpdate.shipTo &&
          value.billTo === this.cardBeforeUpdate.billTo &&
          value.idCardNumber === this.cardBeforeUpdate.idCardNumber &&
          value.taxType === this.cardBeforeUpdate.taxType &&
          value.primaryShipTo === this.cardBeforeUpdate.primaryShipTo &&
          value.primaryBillTo === this.cardBeforeUpdate.primaryBillTo &&
          value.country === this.cardBeforeUpdate.country &&
          value.cityDistrict === this.cardBeforeUpdate.cityDistrict &&
          value.postalCode === this.cardBeforeUpdate.postalCode &&
          value.address === this.cardBeforeUpdate.address &&
          value.picName === this.cardBeforeUpdate.picName &&
          value.picContactNumber === this.cardBeforeUpdate.picContactNumber
        ) {
          index = indexData;
        }
      });
      return index;
    },
    handleUpdateShipTo(indexForUpdate, values) {
      this.listShipTo[indexForUpdate] = {
        shipTo: true,
        billTo: false,
        primaryShipTo:
          !this.listShipTo.find(data => data.shipTo === true) ||
          this.cardBeforeUpdate.primaryShipTo
            ? true
            : false,
        primaryBillTo: false,
        country: values.country,
        cityDistrict: values.city,
        location: this.checkString(values.location),
        postalCode: values.postalCode, // clear
        address: values.address,
        picName: values.picName,
        picContactNumber: values.picContactNumber,
        idCardNumber: values.idCardNumber,
        taxType: values.taxType,
        addressDataLines: this.dataSource,
      };
      this.cardBeforeUpdate = this.listShipTo[indexForUpdate];
      this.$notification.success({
        description: Messages.UPDATE_SUCCESS + " Please Save Data !",
        message: "Success",
        duration: 30,
      });
      this.dataSource = [];
      this.valueCheckbox = ["Ship To"];
      this.titleForm = "lbl_add_new_address";
      this.mode = "create";
      this.plainOptions[0].disabled = false;
      this.plainOptions[1].disabled = false;
      this.cardBeforeUpdate = {} as AddressDataList;
      this.form.resetFields();
    },
    handleUpdateBillTo(indexForUpdate, values) {
      this.listBillTo[indexForUpdate] = {
        shipTo: false,
        billTo: true,
        primaryShipTo: false,
        primaryBillTo:
          !this.listBillTo.find(data => data.shipTo === true) ||
          this.cardBeforeUpdate.primaryBillTo
            ? true
            : false,
        country: values.country,
        cityDistrict: values.city,
        location: this.checkString(values.location),
        postalCode: values.postalCode, // clear
        address: values.address,
        picName: values.picName,
        picContactNumber: values.picContactNumber,
        idCardNumber: values.idCardNumber,
        taxType: values.taxType,
        addressDataLines: this.dataSource,
      };
      this.$notification.success({
        description: Messages.UPDATE_SUCCESS + " Please Save Data !",
        message: "Success",
        duration: 30,
      });
      this.dataSource = [];
      this.cardBeforeUpdate = this.listBillTo[indexForUpdate];
      this.valueCheckbox = ["Ship To"];
      this.titleForm = "lbl_add_new_address";
      this.mode = "create";
      this.plainOptions[0].disabled = false;
      this.plainOptions[1].disabled = false;
      this.cardBeforeUpdate = {} as AddressDataList;
      this.form.resetFields();
    },
    submitForm(e: Event): void {
      e.preventDefault();
      let indexShipTo = null as number | null;
      let indexBillTo = null as number | null;
      this.loadingSave = true;
      this.form.validateFields((err, values) => {
        if (err || this.valueCheckbox.length < 1) {
          this.$notification.error({
            message: "Error",
            description: "Choose 1 Ship To or Bill To",
          });
          return;
        }
        switch (this.mode) {
          case "create":
            this.handleCreate(values);
            this.form.resetFields();
            this.valueCheckbox = ["Ship To"];
            break;
          case "update":
            // check updatenya shipTo / billTo
            if (this.cardBeforeUpdate.shipTo) {
              indexShipTo = this.checkUpdateShipToOrBillTo("Ship To");
            } else if (this.cardBeforeUpdate.billTo) {
              indexBillTo = this.checkUpdateShipToOrBillTo("Bill To");
            }
            // check indexnya
            if (indexShipTo !== null) {
              this.handleUpdateShipTo(indexShipTo, values);
            } else if (indexBillTo !== null) {
              this.handleUpdateBillTo(indexBillTo, values);
            } else {
              this.$notification.error({
                message: "Error",
                description: "Data yang ingin diupdate tidak ditemukan",
              });
            }
            break;
        }
      });
      this.dataSource = [];
      this.listBillTo = this.listBillTo.slice();
      this.listShipTo = this.listShipTo.slice();
      this.commitToStore();
      this.loadingSave = false;
    },
    getRegion(value): void {
      const params = {
        limit: 10,
        city: value.toUpperCase(),
      } as RequestQueryParamsModel;
      this.loadingFetch = true;
      contactServices
        .listRegionCity(params)
        .then(res => {
          res = res.sort();
          this.dataRegionCity = res;
        })
        .finally(() => (this.loadingFetch = false));
    },
    handleChangeRegion(value) {
      this.city = value;
      this.form.resetFields(["postalCode"]);
      this.getListPostalCode("");
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  created() {
    if (this.dataRegionCity.length === 0) {
      this.getRegion("");
    }
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
