var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    _vm._b(
      {
        attrs: { layout: "vertical", form: _vm.form },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitForm.apply(null, arguments)
          }
        }
      },
      "a-form",
      _vm.formItemLayout,
      false
    ),
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            {
              staticStyle: { "margin-top": "2rem", "margin-left": "1rem" },
              attrs: { span: 11 }
            },
            [_c("h2", [_vm._v(_vm._s(_vm.$t("lbl_list_address")))])]
          ),
          _c(
            "a-col",
            {
              staticStyle: {
                display: "flex",
                "margin-top": "2rem",
                "align-content": "flex-end",
                "justify-content": "flex-end"
              },
              attrs: { span: 12 }
            },
            [
              _c(
                "a-button",
                {
                  staticStyle: { "margin-right": "6%" },
                  attrs: { type: "primary" },
                  on: { click: _vm.addNewAddress }
                },
                [_vm._v(" + " + _vm._s(_vm.$t("lbl_add_new_address")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        {
          staticStyle: { "justify-content": "center" },
          attrs: { type: "flex" }
        },
        [
          _c(
            "a-col",
            {
              staticStyle: {
                "margin-top": "2rem",
                "margin-right": "1rem",
                "margin-left": "1rem"
              },
              attrs: { span: 10, xs: 24, s: 24, md: 24, lg: 10 }
            },
            [
              _vm.listShipTo.length === 0
                ? _c(
                    "div",
                    {
                      staticStyle: { "overflow-y": "scroll", height: "140px" }
                    },
                    [
                      _c("a-empty", {
                        attrs: { description: _vm.$t("lbl_no_data_ship_to") }
                      })
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticStyle: { "overflow-y": "scroll", height: "250px" }
                    },
                    _vm._l(_vm.listShipTo, function(dataShipTo, index) {
                      return _c(
                        "a-card",
                        { key: index, staticClass: "customCard" },
                        [
                          dataShipTo.primaryShipTo
                            ? _c(
                                "a-tag",
                                { attrs: { color: "#108ee9" } },
                                [
                                  _c("a-icon", {
                                    staticStyle: { "vertical-align": "0" },
                                    attrs: { type: "tags" }
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_primary")) + " "
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "margin-bottom": "0",
                                "font-weight": "bold"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_ship_to")) + " ")]
                          ),
                          _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                            _vm._v(_vm._s(dataShipTo.address))
                          ]),
                          _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                            _vm._v(_vm._s(dataShipTo.cityDistrict))
                          ]),
                          _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                            _vm._v(_vm._s(dataShipTo.country))
                          ]),
                          _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                            _vm._v(_vm._s(dataShipTo.postalCode))
                          ]),
                          _c(
                            "a-row",
                            {
                              staticStyle: {
                                "justify-content": "flex-end",
                                "margin-top": "1rem"
                              },
                              attrs: { gutter: [16, 16], type: "flex" }
                            },
                            [
                              _c(
                                "a-col",
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    color: "#1890ff"
                                  },
                                  attrs: {
                                    span: "6",
                                    xs: 24,
                                    sm: 24,
                                    md: 8,
                                    lg: 24,
                                    xl: 6
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.setPrimaryCard(
                                        dataShipTo,
                                        index,
                                        "Ship To"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lbl_set_as_primary")) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "a-col",
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    color: "#52c41a"
                                  },
                                  attrs: {
                                    span: "6",
                                    xs: 24,
                                    sm: 24,
                                    md: 8,
                                    lg: 24,
                                    xl: 6
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.updateCard(
                                        dataShipTo,
                                        index,
                                        "Ship To"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_update")) + " "
                                  )
                                ]
                              ),
                              _c(
                                "a-col",
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    color: "#f5222d"
                                  },
                                  attrs: {
                                    span: "6",
                                    xs: 24,
                                    sm: 24,
                                    md: 8,
                                    lg: 24,
                                    xl: 6
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.deleteCard(
                                        dataShipTo,
                                        index,
                                        "Ship To"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_delete")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
            ]
          ),
          _c(
            "a-col",
            {
              staticStyle: { "margin-top": "2rem" },
              attrs: { span: 10, xs: 24, s: 24, md: 24, lg: 10 }
            },
            [
              _vm.listBillTo.length === 0
                ? _c(
                    "div",
                    {
                      staticStyle: { "overflow-y": "scroll", height: "140px" }
                    },
                    [
                      _c("a-empty", {
                        attrs: { description: _vm.$t("lbl_no_data_bill_to") }
                      })
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticStyle: { "overflow-y": "scroll", height: "250px" }
                    },
                    _vm._l(_vm.listBillTo, function(dataBillTo, index) {
                      return _c(
                        "a-card",
                        { key: index, staticClass: "customCard" },
                        [
                          dataBillTo.primaryBillTo
                            ? _c(
                                "a-tag",
                                { attrs: { color: "#108ee9" } },
                                [
                                  _c("a-icon", {
                                    staticStyle: { "vertical-align": "0" },
                                    attrs: { type: "tags" }
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_primary")) + " "
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "margin-bottom": "0",
                                "font-weight": "bold"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_bill_to")) + " ")]
                          ),
                          _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                            _vm._v(_vm._s(dataBillTo.address))
                          ]),
                          _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                            _vm._v(_vm._s(dataBillTo.cityDistrict))
                          ]),
                          _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                            _vm._v(_vm._s(dataBillTo.country))
                          ]),
                          _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                            _vm._v(_vm._s(dataBillTo.postalCode))
                          ]),
                          _c(
                            "a-row",
                            {
                              staticStyle: {
                                "justify-content": "flex-end",
                                "margin-top": "1rem"
                              },
                              attrs: { gutter: [16, 16], type: "flex" }
                            },
                            [
                              _c(
                                "a-col",
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    color: "#1890ff"
                                  },
                                  attrs: {
                                    span: "6",
                                    xs: 24,
                                    sm: 24,
                                    md: 8,
                                    lg: 24,
                                    xl: 6
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.setPrimaryCard(
                                        dataBillTo,
                                        index,
                                        "Bill To"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lbl_set_as_primary")) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "a-col",
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    color: "#52c41a"
                                  },
                                  attrs: {
                                    span: "6",
                                    xs: 24,
                                    sm: 24,
                                    md: 8,
                                    lg: 24,
                                    xl: 6
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.updateCard(
                                        dataBillTo,
                                        index,
                                        "Bill To"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_update")) + " "
                                  )
                                ]
                              ),
                              _c(
                                "a-col",
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    color: "#f5222d"
                                  },
                                  attrs: {
                                    span: "6",
                                    xs: 24,
                                    sm: 24,
                                    md: 8,
                                    lg: 24,
                                    xl: 6
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.deleteCard(
                                        dataBillTo,
                                        index,
                                        "Bill To"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_delete")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
            ]
          )
        ],
        1
      ),
      _c(
        "a-row",
        {
          staticStyle: {
            "justify-content": "center",
            "margin-top": "2rem",
            "margin-bottom": "1rem"
          },
          attrs: { type: "flex", gutter: [16, 16] }
        },
        [
          _c(
            "a-col",
            { attrs: { span: 18, xs: 24, s: 24, md: 24, lg: 18 } },
            [
              _c(
                "a-card",
                { staticStyle: { "border-radius": "5px" } },
                [
                  _c("h2", [_vm._v(" " + _vm._s(_vm.$t(_vm.titleForm)) + " ")]),
                  _c("a-checkbox-group", {
                    staticStyle: {
                      "margin-top": "1rem",
                      "margin-bottom": "2rem"
                    },
                    attrs: { name: "checkboxgroup", options: _vm.plainOptions },
                    model: {
                      value: _vm.valueCheckbox,
                      callback: function($$v) {
                        _vm.valueCheckbox = $$v
                      },
                      expression: "valueCheckbox"
                    }
                  }),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t(_vm.formRules.country.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.country.decorator,
                              expression: "formRules.country.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.country.name,
                            placeholder: _vm.$t(
                              _vm.formRules.country.placeholder
                            ),
                            allowClear: true
                          }
                        },
                        [
                          _c(
                            "a-select-option",
                            { attrs: { value: "indonesia" } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" Indonesia ")
                                  ]),
                                  _vm._v(" Indonesia ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t(_vm.formRules.city.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.city.decorator,
                              expression: "formRules.city.decorator"
                            }
                          ],
                          attrs: {
                            "show-search": "",
                            name: _vm.formRules.city.name,
                            placeholder: _vm.$t(_vm.formRules.city.placeholder),
                            "filter-option": _vm.filterOption,
                            "option-filter-prop": "children",
                            loading: _vm.loadingFetch,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getRegion(value)
                            },
                            change: _vm.handleChangeRegion
                          }
                        },
                        _vm._l(_vm.dataRegionCity, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.postalCode.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.postalCode.decorator,
                              expression: "formRules.postalCode.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.postalCode.name,
                            placeholder: _vm.$t(
                              _vm.formRules.postalCode.placeholder
                            ),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            loading: _vm.loadingPostalCode,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListPostalCode(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataListPostalCode, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t(_vm.formRules.address.label) } },
                    [
                      _c("a-textarea", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.address.decorator,
                            expression: "formRules.address.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.address.name,
                          placeholder: _vm.$t(
                            _vm.formRules.address.placeholder
                          ),
                          rows: 4
                        }
                      })
                    ],
                    1
                  ),
                  _vm.columnTabPane.find(function(c) {
                    return c.value === "Customer Details"
                  })
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.location.label) }
                        },
                        [
                          _c("a-textarea", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.location.decorator,
                                expression: "formRules.location.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRules.location.name,
                              placeholder: _vm.$t(
                                _vm.formRules.location.placeholder
                              ),
                              rows: 2
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t(_vm.formRules.taxType.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.taxType.decorator,
                              expression: "formRules.taxType.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.taxType.name,
                            placeholder: _vm.$t(
                              _vm.formRules.taxType.placeholder
                            ),
                            allowClear: true
                          }
                        },
                        _vm._l(_vm.dataTaxType, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.value } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.value) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.value) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 22, xs: 24, sm: 22 } },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-right": "1rem" },
                          attrs: { type: "primary", loading: _vm.loadingSave },
                          on: { click: _vm.handleAddRow }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "danger" },
                          on: { click: _vm.handleDeleteRow }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("TableCustom", {
                        attrs: {
                          dataSource: _vm.dataSource,
                          columns: _vm.columnsTable,
                          handleInput: _vm.handleInput,
                          paginationcustom: false,
                          defaultPagination: true,
                          scroll: { x: "calc(700px + 50%)", y: 400 },
                          onSelectChange: _vm.onSelectChange,
                          selectedRowKeys: _vm.selectedRowKeys
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end",
                        "margin-bottom": "1rem"
                      },
                      attrs: { span: 24, xs: 24, sm: 24 }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-right": "1rem" },
                          attrs: { type: "primary", loading: _vm.loadingSave },
                          on: { click: _vm.submitForm }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.mode === "create"
                                  ? _vm.$t("lbl_save")
                                  : _vm.$t("lbl_update")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "danger" },
                          on: { click: _vm.handleCancel }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }